import useMutation from 'hooks/useMutation';
import { ApiMethods, CuredApiPaths } from 'models/enums';

const useFilteredData = ({ key, path }: { key: string; path: CuredApiPaths }) => {
    const { mutate: getData, isError, isLoading, data } = useMutation(key, path, {}, ApiMethods.POST);

    return { getData, isError, isLoading, data };
};

export default useFilteredData;
