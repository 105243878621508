import { DateTime } from 'luxon';
import { Campaign, Send } from 'domains/campaigns/types';
import { SendJobType } from 'models/enums';
import { today } from './DateFilter/DateFilter';
import { FiltersForApiState, FilterValuesState } from './types';

export const getIsValidDateRange = (timeframe?: FilterValuesState['filterTimeframe']) => {
    if (!timeframe) return false;

    const { start, end } = timeframe;
    const isValidDates = start?.isValid && end?.isValid;
    const isValidDateStartEnd = start <= end;
    return isValidDates && isValidDateStartEnd;
};

export const getIsValidRelativeTimeframe = (relativeTimeframe?: FilterValuesState['filterRelativeTimeframe']) => {
    if (!relativeTimeframe) return false;

    const { direction, duration, unit } = relativeTimeframe;
    return !!direction && !!duration && !!unit;
};

export const getTimeframeForApi = (
    isRelativeTimeframe: boolean,
    filterValues: FilterValuesState
): FiltersForApiState['timeframe'] => {
    if (isRelativeTimeframe) {
        const { direction, duration, unit } = filterValues.filterRelativeTimeframe;
        const durationString = duration?.toString();
        const offsetValue = direction === 'the last' ? `-${durationString}` : durationString;
        return {
            offset: {
                unit,
                value: offsetValue,
            },
            duration: {
                unit,
                value: durationString,
            },
        };
    }

    return {
        start: filterValues.filterTimeframe.start?.toFormat('yyyy-MM-dd'),
        end: filterValues.filterTimeframe.end?.toFormat('yyyy-MM-dd'),
    };
};

const isCampaign = (item: any): item is Campaign => (item as Campaign).startTime !== undefined;

export const filterByChannelAndTimeframe = <T extends Campaign | Send>({
    data,
    timeframe,
    relativeTimeframe,
}: {
    data: T[];
    timeframe?: FilterValuesState['filterTimeframe'];
    relativeTimeframe?: FilterValuesState['filterRelativeTimeframe'];
}): T[] => {
    const isValidDateRange = getIsValidDateRange(timeframe);
    const isValidRelativeTimeframe = getIsValidRelativeTimeframe(relativeTimeframe);

    if ((!!timeframe && !isValidDateRange) || (!!relativeTimeframe && !isValidRelativeTimeframe)) return;

    return data?.filter((datum) => {
        if (datum.channel === SendJobType.SMS) return false;

        const startTime = isCampaign(datum) ? datum.startTime : datum.scheduledTimestamp;
        const endTime = isCampaign(datum) ? datum.endTime : datum.scheduledEndTimestamp;

        const getStartTime = () => {
            if (!!timeframe) return timeframe.start;
            if (relativeTimeframe.direction === 'the next') return today.startOf('day');
            if (relativeTimeframe.direction === 'the last') {
                return DateTime.now()
                    .minus({ [relativeTimeframe.unit]: relativeTimeframe.duration })
                    .startOf('day');
            }
        };

        const getEndTime = () => {
            if (!!timeframe) return timeframe.end;
            if (relativeTimeframe.direction === 'the last') return today.endOf('day');
            if (relativeTimeframe.direction === 'the next') {
                return DateTime.now()
                    .plus({ [relativeTimeframe.unit]: relativeTimeframe.duration })
                    .startOf('day');
            }
        };

        const filterStart = getStartTime();
        const filterEnd = getEndTime();

        const didNotStartAfterSelectedEnd = startTime <= filterEnd.toISO();
        const didNotEndBeforeSelectedStart = endTime ? endTime >= filterStart.toISO() : true;

        return didNotStartAfterSelectedEnd && didNotEndBeforeSelectedStart;
    });
};
