import * as d3 from 'd3';
import mapData from './mapData.json';
import { brandColors, themeColors } from 'colors';

const COLOR = themeColors.text.secondary;

type BubbleDatum = {
    zip_code: string;
    count: string;
    latitude: number;
    longitude: number;
};

const USMap = ({ bubbleData }: { bubbleData: BubbleDatum[] }) => {
    const projection = d3.geoAlbersUsa();
    const geoPathGenerator = d3.geoPath().projection(projection);
    const worldMap = mapData.features.map((shape) => (
        <path
            key={shape.properties.GEO_ID}
            // @ts-ignore
            d={geoPathGenerator(shape)}
            stroke={themeColors.primary.main}
            strokeWidth={0.5}
            fill={brandColors.mint}
            fillOpacity={0.7}
        />
    ));

    // Set bubble scale
    const valueScale = d3.extent(bubbleData, (d) => +d.count);
    const size = d3
        .scaleSqrt()
        .domain(valueScale)
        // bubble radius min and max
        .range([1, 5]);

    const mapBubbles = bubbleData
        .sort((a, b) => Number(b.count) - Number(a.count))
        .map((bubble, i) => {
            // Find the centroid of the bubble
            const centroid = projection([+bubble.longitude, +bubble.latitude]);
            // Find the radius of the bubble
            const bubbleRadius = size(Number(bubble.count));

            // Draw a bubble (which is just a circle)
            return (
                <circle
                    key={i}
                    r={bubbleRadius}
                    cx={centroid[0]}
                    cy={centroid[1]}
                    opacity={1}
                    stroke={COLOR}
                    fill={COLOR}
                    fillOpacity={0.2}
                    strokeWidth={1}
                />
            );
        });

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
            <svg width="1000px" height="500px">
                {worldMap}
                {mapBubbles}
            </svg>
        </div>
    );
};

export default USMap;
