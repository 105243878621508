import { ResponsiveLine } from '@nivo/line';
import { graphColors } from 'colors';
import CustomTooltip from '../CustomTooltip';
import NoDataMessage from '../NoDataMessage';

type DataPoint = {
    x: string;
    y: string;
};

export type LineGraphData = {
    id: string;
    data: DataPoint[];
};

type Props = {
    data: LineGraphData[];
    bottomLegendLabel?: string;
    leftLegendLabel?: string;
    tickValues?: string;
    tooltipLabel: string;
};

const LineGraph = ({ data, bottomLegendLabel, leftLegendLabel, tickValues, tooltipLabel }: Props) => {
    if (!data || !data[0]?.data?.length) return <NoDataMessage />;

    const dataWithColors = data.map((datum, index) => ({
        ...datum,
        color: graphColors[index],
    }));

    const renderTooltip = (point: any) => (
        <CustomTooltip
            color={point.serieColor}
            label={point.data.xFormatted}
            value={`${tooltipLabel} - ${point.data.yFormatted}`}
        />
    );

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveLine
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: bottomLegendLabel,
                    legendOffset: 36,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                    tickValues: tickValues ?? 'monthly',
                    format: "%b '%y",
                }}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegendLabel,
                    legendOffset: -60,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                    format: (value) => value.toLocaleString(),
                }}
                colors={{ datum: 'color' }}
                data={dataWithColors}
                enableGridX={false}
                margin={{
                    top: 50,
                    right: 50,
                    bottom: bottomLegendLabel ? 60 : 40,
                    left: 80,
                }}
                pointBorderColor={{ from: 'serieColor' }}
                pointBorderWidth={5}
                pointColor={{ theme: 'background' }}
                pointSize={2}
                pointLabel="data.yFormatted"
                pointLabelYOffset={-12}
                tooltip={({ point }) => renderTooltip(point)}
                useMesh={true}
                xFormat="time:%m/%d/%Y"
                xScale={{
                    format: '%Y-%m-%d',
                    precision: 'day',
                    type: 'time',
                    useUTC: false,
                }}
                yFormat=" >-,.2~f"
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                }}
            />
        </div>
    );
};

export default LineGraph;
