import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import DateRangePicker from 'domains/core/components/DateRangePicker';
import { RelativeDateRangeDirection } from 'domains/segments/types';
import { RelativeTimeframeUnit } from 'models/types';
import RelativeDateRangePicker from './RelativeDateRangePicker';
import { FilterValuesState } from '../types';
import { filterByChannelAndTimeframe } from '../utils';

export const today = DateTime.now().endOf('day');
export const oneYearAgo = DateTime.now().minus({ year: 1 }).startOf('day');

type Props = {
    filterValues: FilterValuesState;
    isRelativeTimeframe: boolean;
    setFilterValues: (filters: FilterValuesState) => void;
    setIsRelativeTimeframe: (isRelativeTimeframe: boolean) => void;
};

const DateFilter = ({ filterValues, isRelativeTimeframe, setFilterValues, setIsRelativeTimeframe }: Props) => {
    const handleDateRangeChange = (dateRange: DateRange<DateTime>) => {
        const [dateRangeStart, dateRangeEnd] = dateRange;
        const start = dateRangeStart.startOf('day');
        const end = dateRangeEnd.endOf('day');
        const timeframe = { start, end };

        // Selected curations & sends need to be filtered out if they don't fall within the new date range
        setFilterValues({
            ...filterValues,
            selectedCurationsValue: filterByChannelAndTimeframe({
                data: filterValues.selectedCurationsValue,
                timeframe,
            }),
            selectedSendsValue: filterByChannelAndTimeframe({
                data: filterValues.selectedSendsValue,
                timeframe,
            }),
            filterTimeframe: timeframe,
        });
    };

    const renderDatePicker = () => {
        if (isRelativeTimeframe)
            return <RelativeDateRangePicker filterValues={filterValues} setFilterValues={setFilterValues} />;

        return (
            <DateRangePicker
                onChange={(newValue) => handleDateRangeChange(newValue)}
                value={[filterValues.filterTimeframe.start, filterValues.filterTimeframe.end]}
            />
        );
    };

    const toggleRelativeDateSwitch = () => {
        if (isRelativeTimeframe) {
            setFilterValues({
                ...filterValues,
                filterRelativeTimeframe: {
                    direction: null,
                    duration: null,
                    unit: null,
                },
                filterTimeframe: {
                    start: oneYearAgo,
                    end: today,
                },
            });
        } else {
            setFilterValues({
                ...filterValues,
                filterRelativeTimeframe: {
                    direction: RelativeDateRangeDirection.THE_LAST,
                    duration: 365,
                    unit: RelativeTimeframeUnit.DAY,
                },
                filterTimeframe: {
                    start: null,
                    end: null,
                },
            });
        }
        setIsRelativeTimeframe(!isRelativeTimeframe);
    };

    return (
        <Box alignItems="center" display="flex" mt={2}>
            <Typography variant="body2" mr={2}>
                Curation live within
            </Typography>
            {renderDatePicker()}
            <FormControlLabel
                control={
                    <Switch
                        color="secondary"
                        size="small"
                        checked={isRelativeTimeframe}
                        onChange={toggleRelativeDateSwitch}
                    />
                }
                label="Use relative date range"
                sx={{
                    paddingLeft: 2,
                    '.MuiFormControlLabel-label': {
                        fontSize: '1rem',
                    },
                }}
            />
        </Box>
    );
};

export default DateFilter;
