import { useEffect, useState } from 'react';
import { Send } from 'domains/campaigns/types';
import useSends from 'hooks/queries/useSends';
import FilterAutocompleteInput from '../FilterAutocompleteInput';
import { FilterValuesState } from '../types';
import { filterByChannelAndTimeframe, getIsValidDateRange, getIsValidRelativeTimeframe } from '../utils';

type Props = {
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const SendsFilter = ({ filterValues, setFilterValues }: Props) => {
    const { data, isLoading } = useSends();
    const [sendsToDisplay, setSendsToDisplay] = useState(data);

    useEffect(() => {
        let sends = data;

        if (getIsValidDateRange(filterValues.filterTimeframe)) {
            sends = filterByChannelAndTimeframe({
                data: sends,
                timeframe: filterValues.filterTimeframe,
            });
        }

        if (getIsValidRelativeTimeframe(filterValues.filterRelativeTimeframe)) {
            sends = filterByChannelAndTimeframe({
                data: sends,
                relativeTimeframe: filterValues.filterRelativeTimeframe,
            });
        }

        if (filterValues?.selectedCurationsValue?.length) {
            const selectedCurationIds = filterValues?.selectedCurationsValue.map((curation) => curation.id);
            sends = sends?.filter((send: Send) => selectedCurationIds.includes(send.campaignId));
        }

        setSendsToDisplay(sends);
    }, [data, filterValues]);

    return (
        <FilterAutocompleteInput
            disabled={isLoading}
            label="Send Name"
            labelTooltip="Filter options for sends adjust based on the selected date and Curation filters."
            onChange={(newValue: any) =>
                setFilterValues({
                    ...filterValues,
                    selectedSendsValue: newValue,
                })
            }
            options={sendsToDisplay}
            selectedValues={filterValues.selectedSendsValue}
        />
    );
};

export default SendsFilter;
