import { Grid } from '@mui/material';
import DateFilter from '../../filtering/DateFilter';
import IncludeUnsubsFilter from '../../filtering/IncludeUnsubsFilter';
import { FilterValuesState } from '../../filtering/types';
import { getIsValidDateRange, getIsValidRelativeTimeframe } from '../../filtering/utils';
import FiltersWrapper from '../../filtering/FiltersWrapper/FiltersWrapper';
import SendsFilter from '../../filtering/SendsFilter';
import CurationsFilter from '../../filtering/CurationsFilter';

type Props = {
    filterValues: FilterValuesState;
    handleApplyFilters: () => void;
    isRelativeTimeframe?: boolean;
    setFilterValues: (filters: FilterValuesState) => void;
    setIsRelativeTimeframe?: (isRelativeTimeframe: boolean) => void;
};

const Filters = ({
    filterValues,
    handleApplyFilters,
    isRelativeTimeframe,
    setFilterValues,
    setIsRelativeTimeframe,
}: Props) => {
    const isValidDateRange = getIsValidDateRange(filterValues?.filterTimeframe);
    const isValidRelativeTimeframe = getIsValidRelativeTimeframe(filterValues?.filterRelativeTimeframe);
    const hasInvalidFilters = isRelativeTimeframe ? !isValidRelativeTimeframe : !isValidDateRange;

    return (
        <FiltersWrapper disabled={hasInvalidFilters} handleApplyFilters={handleApplyFilters}>
            <DateFilter
                filterValues={filterValues}
                isRelativeTimeframe={isRelativeTimeframe}
                setFilterValues={setFilterValues}
                setIsRelativeTimeframe={setIsRelativeTimeframe}
            />
            <Grid alignItems="center" container display="flex" mt={0} mb={2} spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <CurationsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <SendsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
                </Grid>
                <Grid item xs={12} md={12} lg={2} minWidth="220px">
                    <IncludeUnsubsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
                </Grid>
            </Grid>
        </FiltersWrapper>
    );
};

export default Filters;
