import { ResponsiveBar } from '@nivo/bar';
import { graphColors } from 'colors';
import CustomTooltip from '../CustomTooltip';
import NoDataMessage from '../NoDataMessage';

type DataItem = {
    id: string | number;
    label?: string;
    value: number;
};

export type BarGraphProps = {
    bottomLegendLabel: string;
    data: DataItem[];
    leftLegendLabel: string;
    useSingleColor?: boolean;
};

const BarGraph = ({ bottomLegendLabel, data, leftLegendLabel, useSingleColor = true }: BarGraphProps) => {
    const hasNonZeroData = data?.map((datum) => datum.value).some((datum) => datum !== 0);
    if (!data || !hasNonZeroData) return <NoDataMessage />;

    const dataWithColors = data.map((datum: DataItem, index: number) => ({
        ...datum,
        color: useSingleColor ? graphColors[0] : graphColors[index],
    }));

    const getFormattedLabel = (label: any) => {
        const { value } = label;
        if (typeof label.value === 'number') {
            return value.toLocaleString();
        }
        return value;
    };

    const renderTooltip = (datum: any) => (
        <CustomTooltip
            color={datum.color}
            label={`${bottomLegendLabel}: ${datum.indexValue}`}
            value={`${leftLegendLabel}: ${datum.value.toLocaleString()}`}
        />
    );

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveBar
                axisBottom={{
                    legend: bottomLegendLabel,
                    legendOffset: bottomLegendLabel ? 40 : 0,
                    legendPosition: 'middle',
                    tickSize: 0,
                }}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegendLabel,
                    legendPosition: 'middle',
                    legendOffset: -50,
                    truncateTickAt: 0,
                    format: (value) => value.toLocaleString(),
                }}
                borderWidth={2}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                colors={{ datum: 'data.color' }}
                data={dataWithColors}
                enableGridY={false}
                label={(label) => getFormattedLabel(label)}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: bottomLegendLabel ? 60 : 32,
                    left: 70,
                }}
                tooltip={(datum) => renderTooltip(datum)}
            />
        </div>
    );
};

export default BarGraph;
