import { Close, InfoOutlined } from '@mui/icons-material';
import { Autocomplete, Chip } from '@mui/material';
import { greyChipStyles } from 'domains/core/components/Chip/Chip';
import InputLabel from 'domains/core/components/InputLabel';
import TextField from 'domains/core/components/TextField';

type AutocompleteObject = {
    label: string;
    id: string;
};

const getAutocompleteObjects = (datums: any[]): AutocompleteObject[] =>
    datums?.map((datum: any) => ({ label: datum.name, id: datum.id }));

type InputProps = {
    disabled?: boolean;
    label: string;
    labelTooltip?: string;
    onChange?: (value: any) => void;
    options?: any[];
    selectedValues: any[];
};

const FilterAutocompleteInput = ({ options, disabled, label, labelTooltip, onChange, selectedValues }: InputProps) => {
    const autocompleteOptions = getAutocompleteObjects(options);
    const autocompleteValues = getAutocompleteObjects(selectedValues);

    return (
        <Autocomplete
            disabled={disabled}
            getOptionLabel={(option: any) => option?.label}
            id={label}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            limitTags={2}
            multiple
            onChange={(event: any, newValue: any) => {
                const valuesForFiltering = newValue.map((value: any) =>
                    options.find((datum: any) => datum.id === value.id)
                );
                return onChange(valuesForFiltering);
            }}
            options={autocompleteOptions ?? []}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={
                        <InputLabel
                            label={label}
                            labelIcon={<InfoOutlined color={disabled ? 'disabled' : 'inherit'} fontSize="inherit" />}
                            labelIconTooltipText={labelTooltip}
                        />
                    }
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        deleteIcon={<Close />}
                        label={option.label}
                        {...getTagProps({ index })}
                        sx={{ ...greyChipStyles }}
                    />
                ))
            }
            sx={{ flex: '1' }}
            value={autocompleteValues ?? []}
        />
    );
};

export default FilterAutocompleteInput;
