import { useEffect, useState } from 'react';
import { Campaign, Send } from 'domains/campaigns/types';
import useCampaigns from 'hooks/queries/useCampaigns';
import { CampaignStatus } from 'models/enums';
import FilterAutocompleteInput from '../FilterAutocompleteInput';
import { FilterValuesState } from '../types';
import { filterByChannelAndTimeframe, getIsValidDateRange, getIsValidRelativeTimeframe } from '../utils';

type Props = {
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const CurationsFilter = ({ filterValues, setFilterValues }: Props) => {
    const { data, isLoading } = useCampaigns();
    const [curationsToDisplay, setCurationsToDisplay] = useState([]);

    useEffect(() => {
        const activeCurations = data?.filter(
            (curation) => curation.status !== CampaignStatus.CANCELED && curation.status !== CampaignStatus.FAILED
        );
        let curations = activeCurations;

        if (getIsValidDateRange(filterValues.filterTimeframe)) {
            curations = filterByChannelAndTimeframe({
                data: curations,
                timeframe: filterValues.filterTimeframe,
            });
        }

        if (getIsValidRelativeTimeframe(filterValues.filterRelativeTimeframe)) {
            curations = filterByChannelAndTimeframe({
                data: curations,
                relativeTimeframe: filterValues.filterRelativeTimeframe,
            });
        }

        if (filterValues?.selectedSendsValue?.length) {
            const selectedSendCampaignIds = filterValues.selectedSendsValue.map((send: Send) => send.campaignId);
            curations = curations?.filter((curation: Campaign) => selectedSendCampaignIds.includes(curation.id));
        }

        setCurationsToDisplay(curations);
    }, [data, filterValues]);

    return (
        <FilterAutocompleteInput
            disabled={isLoading}
            label="Curation Name"
            labelTooltip="Filter options for Curations adjust based on the selected date and send filters."
            onChange={(newValue: any) =>
                setFilterValues({
                    ...filterValues,
                    selectedCurationsValue: newValue,
                })
            }
            options={curationsToDisplay}
            selectedValues={filterValues.selectedCurationsValue}
        />
    );
};

export default CurationsFilter;
