import { useEffect, useMemo } from 'react';
import { CuredApiPaths } from 'models/enums';
import Card from 'pages/InsightsPage/dashboards/Card';
import { FiltersForApiState } from 'pages/InsightsPage/dashboards/filtering/types';
import useFilteredData from 'pages/InsightsPage/dashboards/filtering/useFilteredData';
import { getHeaderName } from 'pages/InsightsPage/visualizations/DataGrid';
import DataGrid, { getValueFormatter, useGetRows } from 'pages/InsightsPage/visualizations/DataGrid';

type Props = {
    filtersForApi: FiltersForApiState;
};

const EngagementByCurationCard = ({ filtersForApi }: Props) => {
    const { getData, isError, isLoading, data } = useFilteredData({
        key: 'curations-dashboard/engagement-by-curation',
        path: CuredApiPaths.REPORT_CURATION_ENGAGEMENT,
    });

    useEffect(() => {
        getData(filtersForApi);
    }, [filtersForApi, getData]);

    const tableData = data as {}[];

    const columns: any = useMemo(() => {
        if (!tableData?.length) return;

        const columnFields = Object.keys(tableData?.[0]);
        const columns = columnFields?.map((field) => ({
            field,
            headerName: getHeaderName(field),
            valueFormatter: ({ value }: { value?: string }) => getValueFormatter(value, field.includes('rate')),
            width: field === 'campaign_name' ? 300 : 100,
        }));

        return columns;
    }, [tableData]);

    const rows = useGetRows(tableData);

    return (
        <Card isError={isError} isLoading={isLoading} label="Engagement by Curation">
            <DataGrid columns={columns} rows={rows} />
        </Card>
    );
};

export default EngagementByCurationCard;
