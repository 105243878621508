import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import NoDataMessage from '../NoDataMessage';

export const getHeaderName = (field: string) => field.replace(/_/g, ' ');

export const getValueFormatter = (value: string, isPercentValue?: boolean) => {
    if (value == null) return '';
    if (isPercentValue) return `${value.toLocaleString()}%`;
    return value.toLocaleString();
};

export const useGetRows = (tableData: any) =>
    useMemo(
        () =>
            tableData?.map((tableDatum: {}, index: number) => ({
                id: index,
                ...tableDatum,
            })),
        [tableData]
    );

const DataGrid = ({ columns, initialState, rows }: any) => {
    if (!columns || !rows) return <NoDataMessage />;

    return (
        <Box
            sx={{
                height: '400px',
                marginTop: 2,
                '& .MuiDataGrid-columnHeaderTitle': {
                    textTransform: 'capitalize',
                },
            }}
        >
            <DataGridPro
                autoHeight={false}
                columns={columns}
                initialState={initialState}
                rows={rows}
                sx={{ border: 0 }}
            />
        </Box>
    );
};

export default DataGrid;
