import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import { FilterValuesState } from '../types';
import { filterByChannelAndTimeframe } from '../utils';

const dateRangeUnits = [
    { label: 'Days', value: 'day' },
    { label: 'Months', value: 'month' },
    { label: 'Years', value: 'year' },
];

type Props = {
    filterValues: FilterValuesState;
    setFilterValues: (filters: FilterValuesState) => void;
};

const RelativeDateRangePicker = ({ filterValues, setFilterValues }: Props) => {
    const handleRelativeDateRangeChange = (relativeTimeframe: FilterValuesState['filterRelativeTimeframe']) => {
        // Selected curations & sends need to be filtered out if they don't fall within the new relative timeframe
        setFilterValues({
            ...filterValues,
            selectedCurationsValue: filterByChannelAndTimeframe({
                data: filterValues.selectedCurationsValue,
                relativeTimeframe,
            }),
            selectedSendsValue: filterByChannelAndTimeframe({
                data: filterValues.selectedSendsValue,
                relativeTimeframe,
            }),
            filterRelativeTimeframe: relativeTimeframe,
        });
    };

    return (
        <Stack alignItems="center" direction="row" columnGap={2}>
            <Typography variant="body2" ml={-1.5}>
                the last
            </Typography>
            <TextField
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const duration = Number(e.target.value);
                    handleRelativeDateRangeChange({
                        ...filterValues.filterRelativeTimeframe,
                        duration,
                    });
                }}
                value={filterValues.filterRelativeTimeframe?.duration ?? ''}
                width={100}
            />
            <Select
                label="Timeframe"
                sx={{ width: '12rem' }}
                value={filterValues.filterRelativeTimeframe?.unit ?? ''}
                values={dateRangeUnits}
                onChange={(e: SelectChangeEvent<unknown>) => {
                    const unit = e.target.value as FilterValuesState['filterRelativeTimeframe']['unit'];
                    handleRelativeDateRangeChange({
                        ...filterValues.filterRelativeTimeframe,
                        unit,
                    });
                }}
            />
        </Stack>
    );
};

export default RelativeDateRangePicker;
